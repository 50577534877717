<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-on:submit.prevent="onSubmit" v-if="show">
          <b-form-group class="form-inline" label="Month/Year">
            <b-form-select
              class="mt-3"
              v-model="item.month"
              :options="months"
              required
            >
              <template v-slot:first>
                <option :value="null">Choose...</option>
              </template>
            </b-form-select>

            <b-form-select
              class="mt-3"
              v-model="item.year"
              :options="years"
              required
            >
              <template v-slot:first>
                <option :value="null">Choose...</option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group role="tablist">
            <b-card
              no-body
              class="mb-1"
              v-for="(horoscope, index) in horoscopes"
              :key="index"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle="'accordion-' + horoscope"
                  variant="info"
                  >{{ horoscope }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + horoscope"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form-textarea
                    :id="'textarea-' + horoscope"
                    v-model="item[horoscope]"
                    :placeholder="horoscope"
                    rows="3"
                    max-rows="8"
                  ></b-form-textarea>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              id="checkbox-isActive"
              v-model="item.isActive"
              name="isActive"
              value="true"
              unchecked-value="false"
              >Active</b-form-checkbox
            >
          </b-form-group>
          <div class="form-submit">
            <b-button type="submit" variant="secondary">
              <b-spinner small v-if="isLoading"></b-spinner>
              Submit</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
.btn-info {
  background-color: #b5b5c3;
  border-color: #b5b5c3;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      isLoading: false,
      item: { year: null, month: null },
      show: true,
      id: null,
      isEditMode: false,
      months: [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" }
      ],
      horoscopes: [
        "aries",
        "taurus",
        "gemini",
        "cancer",
        "leo",
        "virgo",
        "libra",
        "scorpio",
        "sagittarius",
        "capricorn",
        "aquarius",
        "pisces"
      ]
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        this.isLoading = true;
        ApiService.post("monthlyHoroscope/upsert", this.item)
          .then(({ data }) => {
            this.isLoading = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Item has been saved sucessfully!", {
                title: "Success",
                variant: "success"
              });
              this.item = data.response.record;
            } else {
              this.$bvToast.toast("Something went wrong while saving item...", {
                title: "Error",
                variant: "danger"
              });
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast("Something went wrong while saving item...", {
              title: "Error",
              variant: "danger"
            });
          });
      });
    },
    getItem: function() {
      const id = this.$route.params.id || this.item.id || this.$route.query.id;
      this.isEditMode = !!id;

      if (this.isEditMode) {
        return new Promise(resolve => {
          ApiService.get("monthlyHoroscope", { id: id })
            .then(({ data }) => {
              const item =
                data.response && data.response.items.length > 0
                  ? data.response.items[0]
                  : null;
              if (item) {
                this.item = item;
              } else {
                this.item = {};
                this.isEditMode = false;
              }
              resolve();
            })
            .catch(() => {
              this.$bvToast.toast(
                "Something went wrong while retrieving data from external server...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            });
        });
      }
    }
  },
  mounted() {
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Monthly Horoscope", route: "monthlyHoroscope" },
      { title: this.isEditMode ? "Edit Horoscope" : "Add Horoscope" }
    ]);
  },
  computed: {
    years() {
      let currentYear = parseInt(new Date().getFullYear());
      let nextYear = currentYear + 1;
      let years = [];
      years.push({
        value: currentYear,
        text: currentYear
      });
      years.push({
        value: nextYear,
        text: nextYear
      });

      return years;
    }
  }
};
</script>
